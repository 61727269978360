import React from "react";

const SvgAssetsIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <g fill="#8DC8E8">
      <path d="M98.284 125.943l.9-3.2c1 .7 2.8 1.9 4.9 2.1v-3.8c-4.3-1.2-5.5-2.8-5.5-5.4 0-3.1 2.2-4.8 5.5-5v-2.4h1.4v2.4c2 .1 3.7.9 4.7 1.6l-.9 3c-1.2-.7-2.4-1.2-3.8-1.3v2.9c4 1 5.6 2.8 5.6 5.7 0 3.7-2.6 5.2-5.6 5.6v2.6h-1.4v-2.5c-2.7-.3-4.7-1.4-5.8-2.3zm4.4-10.9c0 .6.3 1 1.5 1.3v-2.6c-1 .1-1.5.6-1.5 1.3zm4.4 7.9c0-.7-.5-1.2-1.6-1.6v3.3c1-.1 1.6-.7 1.6-1.7zm7.3 2.7l4.1-4.3c1.7-1.7 3.1-3.3 3.1-5.3 0-1.8-1.3-2.4-2.5-2.4-1.3 0-2.8.6-4.3 1.9v-3.7c1.1-.9 3.5-1.8 5.4-1.8 3.4 0 6.1 2 6.1 5.3 0 3-1.9 5.1-3.6 6.6l-2.8 2.5h6.8v3.5h-12.4l.1-2.3zm16.5-6.5c0-5.8 3.3-9.2 7.6-9.2 4.4 0 7.6 3.4 7.6 9.2 0 5.8-3.3 9.3-7.6 9.3-4.4 0-7.6-3.4-7.6-9.3zm10.5 0c0-4.1-1-5.7-2.9-5.7s-2.9 1.6-2.9 5.7 1 5.8 2.9 5.8 2.9-1.6 2.9-5.8zm9.6-8.6h5.3l4.7 11.1 4.7-11.1h5.1v17.4h-4.2v-9.5c0-.4.1-1.7.1-1.9l-.6 1.9-3.9 9.5h-2.8l-3.9-9.5c-.2-.4-.6-1.7-.6-1.9 0 .1.1 1.5.1 1.9v9.5h-4v-17.4zM98.284 50.643l.9-3.2c1 .7 2.8 1.9 4.9 2.1v-3.8c-4.3-1.2-5.5-2.8-5.5-5.4 0-3.1 2.2-4.8 5.5-5v-2.4h1.4v2.4c2 .1 3.7.9 4.7 1.6l-.9 3c-1.2-.7-2.4-1.2-3.8-1.3v2.9c4 1 5.6 2.8 5.6 5.7 0 3.7-2.6 5.2-5.6 5.6v2.6h-1.4v-2.5c-2.7-.3-4.7-1.4-5.8-2.3zm4.4-10.9c0 .6.3 1 1.5 1.3v-2.6c-1 .1-1.5.6-1.5 1.3zm4.4 7.9c0-.7-.5-1.2-1.6-1.6v3.3c1-.1 1.6-.7 1.6-1.7zm9.6 5v-14.1h-1.9v-2.8l6.4-1v17.8h-4.5zm10.1-8.7c0-5.8 3.3-9.2 7.6-9.2 4.4 0 7.6 3.4 7.6 9.2 0 5.8-3.3 9.3-7.6 9.3-4.4-.1-7.6-3.5-7.6-9.3zm10.5 0c0-4.1-1-5.7-2.9-5.7s-2.9 1.6-2.9 5.7 1 5.8 2.9 5.8c1.9-.1 2.9-1.7 2.9-5.8zm9 0c0-5.8 3.3-9.2 7.6-9.2 4.4 0 7.6 3.4 7.6 9.2 0 5.8-3.3 9.3-7.6 9.3-4.3-.1-7.6-3.5-7.6-9.3zm10.6 0c0-4.1-1-5.7-2.9-5.7s-2.9 1.6-2.9 5.7 1 5.8 2.9 5.8 2.9-1.7 2.9-5.8zm13.3 3.2h-4v-2.3h4v-4h2.3v4h4v2.3h-4v4.1h-2.3v-4.1zm11.6-11.9h5.3l4.7 11.1 4.7-11.1h5.1v17.4h-4.2v-9.5c0-.4.1-1.7.1-1.9l-.6 1.9-3.9 9.5h-2.8l-3.9-9.5c-.2-.4-.6-1.7-.6-1.9 0 .1.1 1.5.1 1.9v9.5h-4v-17.4zM5.384 28.943h79v102.3h-79z" />
      <path opacity={0.52} d="M5.384 131.243h79v48.6h-79z" />
    </g>
  </svg>
);

export default SvgAssetsIcon;
