import React from "react";

const SvgMarketRateIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path fill="#8DC8E8" d="M6.53 13.992h12.2v182.1H6.53z" />
    <path fill="#8DC8E8" d="M6.53 183.892h196.9v12.2H6.53z" />
    <circle
      fill="#8DC8E8"
      cx={127.6}
      cy={213}
      r={13.2}
      transform="translate(-81.37 -66.508)"
    />
    <circle
      fill="#8DC8E8"
      cx={167.2}
      cy={159.6}
      r={13.2}
      transform="translate(-81.37 -66.508)"
    />
    <circle
      fill="#8DC8E8"
      cx={211.3}
      cy={180.3}
      r={13.2}
      transform="translate(-81.37 -66.508)"
    />
    <circle
      fill="#8DC8E8"
      cx={249.5}
      cy={120.8}
      r={13.2}
      transform="translate(-81.37 -66.508)"
    />
    <path
      fill="#8DC8E8"
      d="M42.834 144.177L81.64 87.609l6.761 4.639-38.807 56.567z"
    />
    <path
      fill="#8DC8E8"
      d="M83.086 93.53l3.843-7.244 44.965 23.857-3.843 7.244z"
    />
    <path
      fill="#8DC8E8"
      d="M126.552 111.596l38.164-59.515 6.902 4.426-38.164 59.515z"
    />
  </svg>
);

export default SvgMarketRateIcon;
