import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { animated, useTrail, config } from "react-spring";
import { Row } from "reactstrap";
import { Waypoint } from "react-waypoint";
import Svg100UnitsIcon from "./100UnitsIcon";
import Svg1978Icon from "./1978Icon";
import SvgAssetsIcon from "./AssetsIcon";
import SvgCaliIcon from "./CaliIcon";
import SvgMarketRateIcon from "./MarketRateIcon";
import SvgValueAddIcon from "./ValueAddIcon";

const items = [
  <SvgMarketRateIcon />,
  <Svg100UnitsIcon />,
  <SvgValueAddIcon />,
  <SvgAssetsIcon />,
  <Svg1978Icon />,
  <SvgCaliIcon />
];

const IconsMulti = () => {
  const data = useStaticQuery(graphql`
    query IconmQuery {
      wpgraphql {
        page(id: "cGFnZTo4") {
          acf_icons_multi {
            iconMulti {
              headerText
              headerTextSpan
              offsetNumber
              svgName
            }
          }
        }
      }
    }
  `);

  const icons = data.wpgraphql.page.acf_icons_multi.iconMulti;

  const [on, toggle] = useState(false);
  const trail = useTrail(icons.length, {
    from: { opacity: 0, transform: "translate(0px,100px)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate(0px,0px)" : "translate(0px,100px)"
    },
    config: config.stiff
  });

  return (
    <>
      <Waypoint
        bottomOffset="25%"
        // bottom of window
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      <ul className="icon-list">
        <Row>
          {trail.map(({ ...animation }, index) => (
            <div className={`col-xs-14 col-sm-4 ${icons[index].offsetNumber}`}>
              <animated.li
                className="icon-list__item"
                style={{ ...animation }}
                key={index}
              >
                <span className="icon-list__icon">
                  {items[index]}
                </span>
                <h4 className="heading4">
                  {icons[index].headerText}
                  <span>{icons[index].headerTextSpan}</span>
                </h4>
              </animated.li>
            </div>
          ))}
        </Row>
      </ul>
    </>
  );
};

export default IconsMulti;
