import React from "react";

const SvgFundamentalsIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <circle
      opacity={0.169}
      fill="#DEA029"
      cx={105.516}
      cy={103.516}
      r={62.516}
    />
    <circle fill="#DEA029" cx={105.516} cy={103.515} r={19.952} />
  </svg>
);

export default SvgFundamentalsIcon;
