import React from "react";

const SvgLimitedRentalIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <g fill="#999">
      <path
        opacity={0.285}
        d="M140 91.086l26.725-9.055v54.512L140 130.03V91.086z"
      />
      <path
        opacity={0.571}
        d="M193.45 130.03l-26.725 6.513V82.031l26.725 9.055v38.944z"
      />
    </g>
    <g fill="#999">
      <path
        opacity={0.285}
        d="M18 91.086l26.725-9.055v54.512L18 130.03V91.086z"
      />
      <path
        opacity={0.571}
        d="M71.45 130.03l-26.725 6.513V82.031l26.725 9.055v38.944z"
      />
    </g>
    <path fill="#DEA029" d="M79 81.889L105.725 71v65.543L79 128.712V81.889z" />
    <path
      fill="#D8992E"
      d="M132.45 128.712l-26.725 7.831V71l26.725 10.889v46.823z"
    />
  </svg>
);

export default SvgLimitedRentalIcon;
