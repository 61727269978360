import React from "react";

const SvgValueAddIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path
      fill="#8DC8E8"
      d="M48.3 129.8l3.5-12c3.6 2.6 10.4 7.2 18.3 7.8v-14.3C54.2 107 49.6 100.7 49.6 91c0-11.4 8.2-18 20.5-18.7v-8.9h5.3v8.9c7.4.5 13.8 3.3 17.6 5.9l-3.3 11.3c-4.5-2.6-9-4.4-14.3-5v10.9c14.8 3.8 20.9 10.3 20.9 21.3 0 13.9-9.6 19.5-20.9 20.7v9.5h-5.3v-9.4c-10.1-.3-17.5-4.3-21.8-7.7zm16.4-40.7c0 2.3 1.2 3.8 5.4 4.9v-9.6c-3.6.3-5.4 2.1-5.4 4.7zm16.6 29.6c0-2.6-1.7-4.7-5.9-6v12.5c3.7-.6 5.9-3 5.9-6.5zm48.8-3.2H116v-8h14.1V93.4h8.1v14.1h14.1v8h-14.1v14.2h-8.1v-14.2z"
    />
  </svg>
);

export default SvgValueAddIcon;
