import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { animated, useTrail, config } from "react-spring";
import { Row } from "reactstrap";
import { Waypoint } from "react-waypoint";
import SvgFundamentalsIcon from "./FundamentalsIcon";
import SvgLimitedRentalIcon from "./LimitedRentalIcon";
import SvgEmploymentBaseIcon from "./EmploymentBaseIcon";

const items = [
  <SvgFundamentalsIcon />,
  <SvgLimitedRentalIcon />,
  <SvgEmploymentBaseIcon />
];

const IconsCali = () => {
  const data = useStaticQuery(graphql`
    query IconCQuery {
      wpgraphql {
        page(id: "cGFnZTo4") {
          acf_icons_cali {
            iconsCali {
              headerText
              headerTextSpan
              offsetNumber
              svgName
            }
          }
        }
      }
    }
  `);

  const icons = data.wpgraphql.page.acf_icons_cali.iconsCali;

  const [on, toggle] = useState(false);
  const trail = useTrail(icons.length, {
    from: { opacity: 0, transform: "translate(0px,100px)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate(0px,0px)" : "translate(0px,100px)"
    },
    config: config.stiff
  });

  return (
    <>
      <Waypoint
        bottomOffset="25%"
        // bottom of window
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      <ul className="icon-list">
        <Row>
          {trail.map(({ ...animation }, index) => (
            <div className={`col-xs-14 col-sm-4 ${icons[index].offsetNumber}`}>
              <animated.li
                className="icon-list__item"
                style={{ ...animation }}
                key={index}
              >
                <span className="icon-list__icon">{items[index]}</span>
                <h4 className="heading4 text-yellow">
                  {icons[index].headerText}
                  <span>{icons[index].headerTextSpan}</span>
                </h4>
              </animated.li>
            </div>
          ))}
        </Row>
      </ul>
    </>
  );
};

export default IconsCali;
