import React from "react";

const Svg100UnitsIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path
      d="M14.3 133.1V87.7h-6v-8.9l20.5-3.1V133H14.3zm27-28.2c0-18.7 10.5-29.7 24.6-29.7 14 0 24.6 11 24.6 29.7 0 18.8-10.5 29.8-24.6 29.8-14.2 0-24.6-11-24.6-29.8zm33.9 0c0-13.2-3.3-18.4-9.3-18.4-6.2 0-9.3 5.2-9.3 18.4 0 13.3 3.3 18.5 9.3 18.5 6 0 9.3-5.2 9.3-18.5zm23.2 0c0-18.7 10.5-29.7 24.6-29.7 14 0 24.6 11 24.6 29.7 0 18.8-10.5 29.8-24.6 29.8-14.1 0-24.6-11-24.6-29.8zm33.9 0c0-13.2-3.3-18.4-9.3-18.4-6.2 0-9.3 5.2-9.3 18.4 0 13.3 3.3 18.5 9.3 18.5 6 0 9.3-5.2 9.3-18.5zm47.3 10.5h-14.1v-8h14.1V93.3h8.1v14.1h14.1v8h-14.1v14.2h-8.1v-14.2z"
      fill="#8DC8E8"
    />
  </svg>
);

export default Svg100UnitsIcon;
