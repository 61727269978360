import React from "react";

const Svg1978Icon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path
      d="M10.8 130.9V88.3H5.2V80l19.3-2.9v53.8H10.8zm41.4-54.3c9.1 0 21.1 4.3 21.1 24.9 0 19-8.8 30.7-24.4 30.7-6.4 0-11.7-2.3-13.7-3.6v-9.9c3.3 2 8 3.5 11.8 3.5 6.8 0 12.1-3 12.4-14.2-1.7 2.3-6.5 4.6-11 4.6-9.8 0-16.5-7.1-16.5-17.6.1-11 8.7-18.4 20.3-18.4zm7.5 18.5c0-5.9-2.9-9.5-7.1-9.5-4.5 0-7 4-7 8.9 0 5.1 2.4 8.9 6.7 8.9 3.5.1 7.4-2 7.4-8.3zm42.4-6.1H77.8V78.3H117v6.2c-4.3 6.5-16.5 29.8-19.2 46.3H83.5c2.3-10.8 19.6-43.2 18.6-41.8zm38.4 43.4c-12 0-21.7-6.1-21.7-16.1 0-7.1 4.6-12.1 11.2-14.6-5.3-2.5-8.1-6.8-8.1-11.7 0-8.1 8.8-13.3 18.6-13.3 9.6 0 18.5 5.3 18.5 13.3 0 4.9-2.7 9.3-8 11.7 6.5 2.5 11.1 7.5 11.1 14.6 0 9.9-9.7 16.1-21.6 16.1zm0-25.4c-4.8 0-7.9 3.4-7.9 8.2 0 4.7 3.1 8.3 7.9 8.3 4.8 0 7.8-3.6 7.8-8.3 0-4.8-3-8.2-7.8-8.2zm-5.8-16.1c0 3 2.1 6.1 5.8 6.1s5.7-3.2 5.7-6.1c0-3.5-2-6-5.7-6-3.8 0-5.8 2.5-5.8 6zM183 112h-14.1v-8H183V89.9h8.1V104h14.1v8h-14.1v14.2H183V112z"
      fill="#8DC8E8"
    />
  </svg>
);

export default Svg1978Icon;
