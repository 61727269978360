import React from "react";

const SvgEmploymentBaseIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path
      fill="#F5E2BF"
      d="M105 34.458l-43.015 27.87h14.54v54.526h58.162V62.328h13.328z"
    />
    <path
      fill="#EAC174"
      d="M105 66.864L61.985 94.733h14.54v54.526h58.162V94.733h13.328z"
    />
    <path
      fill="#DEA029"
      d="M105 99.269l-43.015 27.869h14.54v54.526h58.162v-54.526h13.328z"
    />
  </svg>
);

export default SvgEmploymentBaseIcon;
